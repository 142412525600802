<script>
import { getAction } from '@/command/netTool'
import moment from 'moment'

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      activityBackground: [],
      arrData: []
    }
  },
  watch: {
    value: {
      handler(newValue) {
        if (!this.init && newValue && typeof newValue == 'string') {
          this.init = true
          const keys = typeof newValue == 'string' ? newValue.split(',') : []
          this.getList({ select: keys })
        }
      },
      immediate: false
    },
    'item.shopId': {
      handler(newValue) {
        if (newValue) {
          this.getList()
        }
      }
    },
    activityBackground(newValue) {
      this.$emit('change', newValue)
    }
  },
  mounted() {
    this.getList()
    this.arrData = this.item.selectArr ? this.item.selectArr.split(',') : []
  },
  methods: {
    getList() {
      this.activityBackground = []
      getAction('/api/commodity/farmNativeProduct/queryCertData?shopId=' + this.item.shopId).then(e => {
        if (this.item.typeList == 2 && e.data.inspection.length > 0) {
          this.activityBackground = e.data.inspection.map((e, i) => ({
            image2: e,
            isOpen: this.arrData.includes(e) ? true : false
          }))
        } else if (this.item.typeList == 1 && e.data.cert.length > 0) {
          this.activityBackground = e.data.cert.map((e, i) => ({
            image2: e,
            isOpen: this.arrData.includes(e) ? true : false
          }))
        }
      })
    },
    getValue() {
      return this.activityBackground
    },
    radioSelect(data) {
      this.getValue().forEach(element => {
        element.select = false
      })
      data.select = !data.select
    },
    renderValue() {
      let that = this
      return (
        <div class="action-upload-img-group">
          {that.activityBackground.length > 0
            ? that.getValue().map(e => {
                const isSelect = e.select
                return (
                  <div class="v_box">
                    <div
                      //   onClick={() => that.radioSelect(e)}
                      class={`action-upload-item-${isSelect ? 'select' : 'normal'} img-box`}
                      style={{ backgroundImage: 'url(' + e.image2 + ')' }}
                    >
                      <div class="popup">
                        <img
                          style="width: 18px;height: 16px"
                          src={require('@/assets/yj.png')}
                          onClick={() => {
                            window.open(e.image2)
                          }}
                        />
                      </div>
                    </div>
                    <a-switch
                      style={{
                        marginLeft: '-16px',
                        marginTop: '10px'
                      }}
                      checked={e.isOpen}
                      onChange={() => (e.isOpen = !e.isOpen)}
                    />
                  </div>
                )
              })
            : {
                1: '未发现主体三品一标信息',
                2: '未发现主体检验合格证'
              }[that.item.typeList] || '暂无信息'}
        </div>
      )
    }
  },
  render() {
    return <div class="action-upload-main">{this.renderValue()}</div>
  }
}
</script>
<style lang="less" scoped>
.img-box {
  position: relative;

  .popup {
    display: none;
  }

  &:hover {
    .popup {
      position: absolute;
      top: 0%;
      left: 0%;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.v_box {
  text-align: center;
}
.action-upload-main {
  display: flex;
  flex-wrap: wrap;
}
.action-upload-img-group {
  display: flex;
  flex-wrap: wrap;
  img {
    width: 80px;
    height: 100px;
  }
}
.action-upload-item-normal {
  display: flex;
  flex-direction: column;
  width: 100px;
  height: 100px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  justify-content: flex-end;
  padding-bottom: 20px;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 10px;
}
.action-upload-item-select:extend(.action-upload-item-normal) {
  border: 3px solid rgba(44, 140, 240, 1);
  border-radius: 3px;
}
.action-uploader {
  background: #f5f5f5;
  width: 80px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 10px;
}
</style>
